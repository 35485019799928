import QrScanner from "qr-scanner";
import {
    initI18Next,
    NS,
    createTranslator,
} from "../../../../components-shared/utils/init-i18next.js";

export class ProductQrCodeScanner extends HTMLElement {
    constructor() {
        super();
    }

    /* #region Private */
    private videoCont: HTMLDivElement;
    private video: HTMLVideoElement;
    private spinner: HTMLDivElement;
    private qrScanner: QrScanner;
    private isScanning = false;
    private t = createTranslator(NS.COMMON_PRODUCT);
    private lastFoundUrl: string;
    private lastFoundTimeout: number;
    private lastFoundDate: Date;

    private init() {
        const styles = document.createElement("style");
        styles.textContent = `
            .video-container {
                overflow: hidden;
                position: fixed;
                top: 0;
                bottom: 0;
                left: 0;
                right:0;
                z-index: 1;
                background-color: #1e1e1ee6;
                justify-content: center;
                align-items: center;
                flex-direction: column;
            }

            .video-container video {
                width: 100%;
                display: block;
            }
        `;

        this.appendChild(styles);

        // Scan button
        const scanButtonText = (isScanning: boolean) => this.t(isScanning ? "closeQrScanner" : "openQrScanner");

        const scanButton = document.createElement("button");
        scanButton.textContent = scanButtonText(this.isScanning);
        scanButton.classList.add("btn", "btn-primary", "btn-scanqr");
        scanButton.addEventListener("click", async (e: MouseEvent) => {
            e.preventDefault();

            if (this.isScanning) {
                this.closeScan();
                this.lastFoundUrl = null;
                scanButton.innerHTML = scanButtonText(this.isScanning);
                return;
            }

            await this.openScan();
            scanButton.innerHTML = scanButtonText(this.isScanning);
        });

        // Video container
        this.videoCont = document.createElement("div");
        this.videoCont.classList.add("video-container");
        this.videoCont.style.display = "none";
        this.appendChild(this.videoCont);

        const closeScanButton = document.createElement("button");
        closeScanButton.type = "button";
        closeScanButton.classList.add("btn", "btn-scanqr-close");
        closeScanButton.innerHTML = ``;
        closeScanButton.addEventListener("click", (e: MouseEvent) => {
            e.preventDefault();
            this.closeScan();
            this.videoCont.style.display = "none";
            this.lastFoundUrl = null;
            //scanButton.innerHTML = scanButtonText(this.isScanning);
        });

        this.videoCont.appendChild(closeScanButton);

        this.spinner = document.createElement("div");
        this.spinner.classList.add("spinner-border", "text-primary", "d-none");
        this.spinner.style.width = "3rem";
        this.spinner.style.height = "3rem";

        this.videoCont.appendChild(this.spinner);

        this.appendChild(scanButton);

        this.video = document.createElement("video");
        this.videoCont.appendChild(this.video);

        // QR Scanner
        this.qrScanner = new QrScanner(
            this.video,
            (result) => {
                this.onQrCodeFound(result.data);
            },
            {
                maxScansPerSecond: 10,
                highlightScanRegion: true,
                highlightCodeOutline: true,
            }
        );
    }

    private async openScan() {
        this.videoCont.style.display = "flex";
        this.isScanning = true;
        this.scrollToThis();
        await this.qrScanner.start();
    }

    private closeScan() {
        this.isScanning = false;
        this.qrScanner.stop();

        this.forceReflow();

        if (this.lastFoundTimeout) {
            window.clearTimeout(this.lastFoundTimeout);
        }
    }

    private onQrCodeFound(data: string) {
        let url: URL;
        try {
            url = new URL(data);
        } catch {
            return;
        }

        const relativeUrl = url.pathname + url.search + url.hash;

        if (
            !url ||
            !url.pathname.startsWith("/sku/") ||
            (url.pathname.match(/\//g) || []).length !== 2 ||
            this.lastFoundUrl === relativeUrl
        ) {
            if (this.lastFoundUrl === relativeUrl) {
                this.lastFoundDate = new Date();
            } else {
                this.lastFoundDate = null;
                this.lastFoundUrl = null;
            }

            return;
        }

        this.lastFoundDate = new Date();
        this.lastFoundUrl = relativeUrl;

        if (this.lastFoundTimeout) {
            window.clearTimeout(this.lastFoundTimeout);
        }

        this.lastFoundTimeout = window.setTimeout(() => {
            if (new Date().getTime() - this.lastFoundDate.getTime() < 200 && this.lastFoundUrl?.length > 0) {
                this.videoCont.removeChild(this.video);
                this.spinner.classList.remove("d-none");
                this.closeScan();
                window.location.assign(this.lastFoundUrl);
            } else {
                this.lastFoundDate = null;
                this.lastFoundUrl = null;
            }
        }, 1000);

        this.forceReflow();
    }

    private forceReflow() {
        this.offsetHeight;
    }

    private scrollToThis() {
        this.scrollIntoView({ behavior: "smooth" });
    }
    /* #endregion */

    async connectedCallback() {
        await initI18Next(NS.COMMON_PRODUCT);
        this.init();
    }
}

customElements.define("product-qr-code-scanner", ProductQrCodeScanner);
